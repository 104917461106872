
import Vue from "vue";
// @ts-ignore
import CardAutocomplete from '@/components/Content/CardAutocomplete.vue';
// @ts-ignore
import CardTextField from '@/components/Content/CardTextField.vue';
// @ts-ignore
import { isRequired, isMaxLength } from '@/services/rule-services';
import { isEmpty, isNull, filter, isUndefined } from 'lodash';
// @ts-ignore
import { SortingOption } from "@/interfaces/paginated";
// @ts-ignore
import { SegmentCategoryFilter, SegmentCategoryDataCreate } from '@/interfaces/segment_category'
// @ts-ignore
import { initSegmentsCategory } from '@/utils/initData';
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import { mapActions } from "vuex";

export default Vue.extend({
    name: "Overview",

    components: { CardAutocomplete, CardTextField },

    props:{
        segment_id_route:{
            type: Number,
            required: false
        },

        advertiser_id_route:{
            type: Number,
            required: false
        }
    },

    data: () => ({
        dialog: false,
        dialogRemove: false,
        countRemove: 0,
        advertiser_id_select: null,
        valid: true,
        valueItem: {},
        segmentCategory: {},
        advertiser_rule: [],
        name_rules: [],
        items: [],
        subcategories: [],
        params_segment_category: {
            filters: {} as SegmentCategoryFilter,
            options: { sort: "name", order: "desc" } as SortingOption
        },
    }),

    async created() {
        this.$nextTick(async () => {
            this.segmentCategory = initSegmentsCategory();
            await this.fetchSegmentsCategories();
            await this.fetchAdvertiser();
            this.getSegmentCategoriesByParentID(this.getSegmentsCategories);
        });
    },

    async mounted() {
        this.$nextTick(async () => {
            this.loadResources();
        })
    },

    beforeDestroy(){
        this.$store.state.advertiser.advertisers_list = []
    },
    
    computed: {

        getRules() {
			return {
				isRequired,
				isMaxLength
			} 
		},

        getAdvertisers() {
            return this.$store.state.advertiser.advertisers_list;
        },

        getSegmentsCategories() {
            return this.$store.state.segment_category.segments_categories;
        },

    },

    methods: {
		...mapActions("loading", ["setLoadingData"]),

        isVisible(item){
            return item.extra <= 4;
        },

        async loadResources(){
            if(this.advertiser_id_route > 0){
                this.advertiser_id_select = this.advertiser_id_route
                this.params_segment_category.filters = { advertiser_id: this.advertiser_id_select }
                this.fetchSegmentsCategories()
                .then((result: any) =>{
                    this.getSegmentCategoriesByParentID(this.getSegmentsCategories);
                })
                .catch((err)=>{

                });
            }
        },

        clearRules(){
            this.name_rules = [];
            this.advertiser_rule = [];
        },

        /*
		* Metodos del treeview
		*/

        getSegmentCategoriesByParentID(values: any){
            this.items = [];
            if(isEmpty(values)) return
            let parents = this.getSegmentCategoriesParent(null);
            parents.forEach(element => {
                let hijos = this.recursividad(element.id, 1);
                let item = {
                    id: element.id,
                    name: element.name,
                    parent_id: element.parent_id,
                    advertiser_id: element.advertiser_id,
                    children: hijos,
                    extra: 1
                }
                this.items.push(item)
            });
        },

        recursividad(PID: number, valor: number){
            let segmentos = this.getSegmentCategoriesParent(PID);
            let arr = new Array();
            if(isEmpty(segmentos)) return 
            segmentos.forEach(element => {
                let hijos = this.recursividad(element.id, valor + 1);
                let item = {
                    id: element.id,
                    name: element.name,
                    parent_id: element.parent_id,
                    advertiser_id: element.advertiser_id,
                    children: hijos,
                    extra: valor + 1
                }
                arr.push(item)
                if(!isNull(element.parent_category)){
                    this.recursividad(element.id, valor + 1);
                }
            })
            return arr;
        },

        getSegmentCategoriesParent(params: any){
            return filter(this.getSegmentsCategories, { "parent_id": params });
        },

        delCategory(item, items) {
            this.subcategories = [];
            this.countRemove = 0;
            if(isUndefined(item.children) || isEmpty(item.children)){
                this.setLoadingData(TypeLoading.loading)
                this.dispatchDeleteSegmentCategory(item.id)
                .then(async (result : any) => {
                    this.removeCategory(items, item);
                    await this.setLoadingData();
                }).catch(async (err) =>{
                    await this.setLoadingData();
                })
            }else{
                this.dialogRemove = true;
                this.subcategories = this.shearAllChildren(item);
                this.countRemove = this.subcategories.length;
            }
        },

        removeCategory(values: any, item){
            if(isEmpty(values)) return
            values.forEach((element, index) => {
                let hijos = this.getFuncionRecursiva(element.children, item.id, true, false);
                let obj = {
                    id: element.id,
                    name: element.name,
                    children: hijos
                }
                if(obj.id == item.id){
                    values.splice(index, 1);
                }
            });
        },
        
        shearAllChildren(item){
            let arr = new Array();
            item.children.forEach((element, index) => {
                let hijos = this.getFuncionRecursiva(element.children, item.id, false, true, arr);
                let obj = {
                    id: element.id,
                    name: element.name,
                    children: hijos
                }
                arr.push(obj.id)
            });
            return arr;
        },

        getFuncionRecursiva(values: any, ID: number, remove: boolean, search: boolean, arrHijo: [any]){
            let arr = new Array();
            if(isEmpty(values)) return 
            values.forEach((element, index) => {
                let hijos = this.getFuncionRecursiva(element.children, ID, remove, search, arrHijo);
                let obj = {
                    id: element.id,
                    name: element.name,
                    children: hijos
                }
                arr.push(obj);
                
                if(search){
                    arrHijo.push(obj.id);
                }

                if(remove){
                    if(obj.id == ID){
                        values.splice(index, 1);
                    }
                }

                if(!isNull(element.children)){
                    this.getFuncionRecursiva(element.id);
                }
            })
            return arr;
        },

        redirectSegment() {
            if(this.segment_id_route > 0){
                this.$router.push({ name: "SegmentsEdit", params: { id: this.segment_id_route } });
            }else{
                this.$router.push({ name: "SegmentsIndex" });
            }
        },

        handleClose(){
            this.dialog = false;
            this.dialogRemove = false;
            this.segmentCategory.parent_id = null;
            this.segmentCategory.name = "";
        },

        async selectSegmentCategory() {
            if(isNull(this.advertiser_id_select)){
                this.params_segment_category.filters = {}
            }else{
                this.params_segment_category.filters = { advertiser_id: this.advertiser_id_select }
            }
            this.fetchSegmentsCategories()
            .then((result: any) =>{
                this.getSegmentCategoriesByParentID(this.getSegmentsCategories);
            })
            .catch((err)=>{

            });
        },

        async addSegmentCategories(item, subitem: boolean) {
            this.setLoadingData(TypeLoading.loading)
            
            this.segmentCategory.parent_id = subitem ? item.id : undefined;
            this.segmentCategory.advertiser_id = isNull(this.advertiser_id_select) ? undefined : this.advertiser_id_select;
            
            this.dispactchSaveSegmentCategory({ seg: this.segmentCategory })
            .then(async (result: any) => {
                let valueResult = {
                    id: result.id,
                    name: result.name,
                    advertiser_id: result.advertiser_id,
                    parent_id: result.parent_id,
                    children: undefined,
                    extra: subitem ? item.extra + 1 : 1
                };
                if(subitem){
                    if(isUndefined(item.children)){
                        item.children = [];
                        item.children.push(valueResult);

                    }else{
                        item.children.push(valueResult);
                    }
                }else{
                    this.items.push(valueResult);
                }
                this.segmentCategory = initSegmentsCategory();
                this.valueItem = {};
                this.clearRules();
                await this.setLoadingData();
                this.dialog = false;
            }).catch(async (err) => {
                this.dialog = false;
                this.segmentCategory = initSegmentsCategory();
                this.valueItem = {};
                this.clearRules();
                await this.setLoadingData();
            });
        },

        async addRules(){
            this.name_rules = [this.getRules.isRequired, this.getRules.isMaxLength]
        },

        async addCategory(item) {
            this.dialog = true;
            if(isNull(item)){
                this.valueItem = null;
                this.segmentCategory.parent_id = null;
            }else{
                this.valueItem = item;
                this.segmentCategory.parent_id = item.id;
            }
        },

        async handleSubmit(){
            await this.addRules();
            if (!(await this.validate())) return;
            if(isNull(this.valueItem)){
                this.addSegmentCategories(this.valueItem, false);
            }else{
                this.addSegmentCategories(this.valueItem, true);
            }
        },

        async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

        async setLoadingField(loading: boolean = false) {
			return this.$store.dispatch("proccess/setLoadingField", loading);
		},

        /*
		* Metodos Fetch
		*/

		async fetchAdvertiser(e: any) {
			//if (!isEmpty(this.getAdvertisers)) return;
			this.$emit("fetch-advertiser");
		},

        async fetchSegmentsCategories(){
            try {
                await this.setLoadingData(TypeLoading.loading);
                await this.dispatchSegmentCategory();
                await this.setLoadingData();
            } catch (error) {
                await this.setLoadingData();
            }
        },

        /*
		* Metodos Dispach
		*/
        async dispatchSegmentCategory() {
            return this.$store.dispatch("segment_category/all", this.params_segment_category, {
                root: true,
            });
        },

        async dispactchSaveSegmentCategory(params: { seg: SegmentCategoryDataCreate }){
            return this.$store.dispatch("segment_category/create", params);
        },

        async dispatchDeleteSegmentCategory(id: Number){
            return this.$store.dispatch("segment_category/delete", id);
        }
    }

});

